.MuiCardPadding {
  padding: 24px;
}

.MuiCardImagePadding {
  padding: 80px 24px;
}

.circlebox .MuiButtonBase-root {
  border-radius: 100%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background: #efefef75;
}

.circlebox .MuiButtonBase-root span {
  color: #326c43;
  font-size: 14px;
}
.circlebox .circle_icon_sec img {
  margin: 0 auto;
  max-width: 27px;
  filter: opacity(0.5);
  margin-bottom: 6px;
}
.image_restrict {
  font-size: 14px;
  color: #326c43;
  text-align: center;
  display: block;
  max-width: 200px;
  margin: 0 auto;
  display: block;
  padding-top: 15px;
}
.formError {
  color: #f71701;
  font-size: 0.9rem;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 55px;
}
.react-datepicker-popper {
  z-index: 2;
}
.btn-Margin {
  margin: 8px;
}
.pac-container {
  background-color: #fff;
  position: absolute!important;
  z-index: 9999 !important;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px #000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.textEllipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}