* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
}

body {
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}

input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

textarea::-webkit-input-placeholder {
    color: var(--text-disabled-color);
}

textarea::-moz-placeholder {
    opacity: 1;
    color: var(--text-disabled-color);
}

textarea:-ms-input-placeholder {
    color: var(--text-disabled-color);
}

textarea::placeholder {
    color: var(--text-disabled-color);
}

a {
    color: #3366FF
}

img {
    display: block;
    max-width: 100%;
}

.apexcharts-tooltip,
.apexcharts-xaxistooltip {
    border: 0 !important;
    box-shadow: 0 0 4px 0 #5286A0, 0 24px 48px 0 #5286A0 !important;
    color: #212B36 !important;
    border-radius: 12px !important;
    background-color: #fff !important;
}

.apexcharts-tooltip-title {
    border: 0 !important;
    font-weight: 700;
    background-color: #5B758B !important;
    color: #637381;
}

.apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: transparent !important;
}

.apexcharts-xaxistooltip-bottom:after {
    border-bottom-color: #fff !important;
}

.apexcharts-legend {
    padding: 0 !important;
}

.apexcharts-legend-series {
    align-items: center;
    display: flex !important;
}

.apexcharts-legend-marker {
    margin-top: -2px !important;
    margin-right: 8px !important;
}

.apexcharts-legend-text {
    line-height: 18px;
    text-transform: capitalize;
}


.newButton {
    position: absolute;
    right: 10px;
}

.addCouponBtn{
    width: 60%;
}

.searchLocation {
    padding-top: 10px;
    position: relative;
    width: 100%;
}

.clearBtn {
    width: fit-content;
    height: fit-content;
    background-color: white;
    position: absolute;
    right: 0;
    top: 10;
    margin: 7px 7px 0 0;
}

.formControl {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.textField {
    padding-bottom: 10px;
}

.searchInput {
    width: 15%;
}

.pageContent {
    margin: 40px;
    padding: 24px;
}

.table {
    min-width: 450px;
}

.heading {
    justify-content: center;
}

.use_gps {
    border: 1px solid;
    padding-left: 20px;
    cursor: pointer;
}

.use_gps .form-control {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.use_gps .form-control h6 {
    color: #736b82;
    font-size: 12px;
}

.use_gps .form-control b {
    display: block;
    color: #286836;
    font-size: 14px;
}

.googleMap {
    height: 200px;
    padding-top: 20px;
}

.textField {
    padding-bottom: 10px;
}

.addCoupon {
    left: 213px;
    bottom: 106px;
}

.datePicker input {
    width: 100%;
    height: 54px;
    margin: 8px;
    line-height: 1.4375em;
    font-size: 1rem;
    padding: 9px;
}

.couponContainer {
    border: 1px solid #c6c6c6;
    border-radius: 8px;
    padding: 15px;
    margin: 5px;
}

.couponContainer span {
    color: #736b82;
    font-size: 16px;
    margin-top: 6px;
}

.couponHead {
    color: #286836;
    font-weight: 600;
    font-size: 20px;
}

;

.container1 {
    display: flex;
    flex-direction: column;
}

.couponCheck {
    text-align: right;
    align-items: right;
}

.buttonContainer {
    padding-top: 20px;
    align-items: right;
    text-align: right;
}

.couponContainerAdd {
    display: flex;
}

.couponField {
    width: 80%;
}

.viewCouponText {
    padding: 20px 5px 0px 0px;
    color: #00AB55;
    cursor: pointer;
}

.root {
    margin: 4px;
}

.label {
    text-transform: none;
}

.rootListCoupon {
    min-width: 0;
    margin: 4px;
}

.secondary {
    background-color: #dc3545;
    color: #fff;
}

.secondary .MuiButton-label {
    color: #3366FF;
}

.primary {
    background-color: #00ab55;
    color: #fff;
}

.primary .MuiButton-label {
    color: #fff;
}

.rootComponent {
    width: auto !important;
}

.field .MuiFormLabel-root.Mui-disabled {
    color: #637381;
}

.button {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.dialogWrapper {
    padding: 6px;
    position: absolute;
    top: 0px;
}

.dialogTitle {
    padding-right: 0px;
}

.rootUseForm .MuiFormControl-root {
    width: 100%;
    margin: 8px;
}

.useTable {
    margin-top: 24px;
}

.useTable thead th {
    font-weight: 600;
    color: #fff;
    background-color: #00ab55;
}

.useTable tbody td {
    font-weight: 300;
}

.useTable tbody tr:hover {
    background-color: #fffbf2;
    cursor: pointer;
}
.ck-editor__editable {
    min-height: 250px;
}